// web_specPlaceAnOrder 分销商特价产品下单

const __request = require(`./__request/__request_contentType_json`);

// 接口地址： http://192.168.0.15:8080/doc.html#/所有接口/tour-order-controller/specPlaceAnOrderUsingPOST

export default function web_specPlaceAnOrder(pParameter) {
  if (!pParameter) pParameter = {};
  const adultPopulation = pParameter.adultPopulation || 0
  const childrenBedPopulation = pParameter.childrenBedPopulation || 0
  const agedPopulation = pParameter.agedPopulation || 0
  const childrenPopulation = pParameter.childrenPopulation || 0
  const confirmWay = pParameter.confirmWay || ''
  const orderNo = pParameter.orderNo || ''
  const otherCost = pParameter.otherCost || []
  const paymentServiceRates = pParameter.paymentServiceRates || ''
  const platformServiceRate = pParameter.platformServiceRate || ''
  const productId = pParameter.productId || ''
  const teamId = pParameter.teamId || ''
  const teamTime = pParameter.teamTime || ''
  const travelAgency = pParameter.travelAgency || {}
  let params = {}
  params.method = 'POST'
  params.urlSuffix = '/web/specPlaceAnOrder'
  params.data = {
    adultPopulation,
    childrenBedPopulation,
    agedPopulation,
    childrenPopulation,
    confirmWay,
    orderNo,
    otherCost,
    // paymentServiceRates,
    // platformServiceRate,
    productId,
    teamId,
    teamTime,
    travelAgency
  }
  return new Promise(function(resolve, reject) {
    resolve(__request(params))
  })
}

