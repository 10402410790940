const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 预定切位订单
 */
export default function getDetail(val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/common/productDetail"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
} 