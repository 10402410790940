/* jshint esversion: 6 */
import web_common_productDetail from "@/lib/data-service/default/web_common_productDetail";
import web_specPlaceAnOrder from "@/lib/data-service/default/web_specPlaceAnOrder";
import web_tourismFullReduction_orderReductionMsg from '@/lib/data-service/default/web_tourismFullReduction_orderReductionMsg'
import moment from "moment";
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
      if (regExp.test(value)) {
        callback();
      } else {
        callback(new Error("不符合手机号码格式"));
      }
    };
    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback();
        return;
      }
      const regExp = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (regExp.test(value)) {
        callback();
      } else {
        callback(new Error("邮件格式不正确"));
      }
    };
    return {
      productNo: "",
      tourTeamId: "",
      detailData: {},
      tourismTeamResult: [],
      tourismNotice: {},
      tourismCost: [],
      tourismCostNumArr: [],
      orderNo: "0",

      productIndex: 0,

      adultNumber: 0,
      childNumber: 0,
      childBedNumber: 0,
      agedNumber: 0,

      contactParams: {
        name: "",
        phone: "",
        email: "",
        descreiption: "",
      },
      rule: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          { max: 11, message: "不符合手机号码长度", trigger: "blur" },
          { validator: validatePhone, trigger: "blur" },
        ],
        email: [
          /*{ required: true, message: '请输入邮件地址', trigger: 'blur' },*/
          { validator: validateEmail, trigger: "blur" },
        ],
      },

      resultAmount: {}, // 最终计算金额
      resultPrice: 0,
      totalPrice: 0,
    };
  },
  components: {},
  created() {
    document.documentElement.scrollTop = 0;
    this.productNo = this.$route.query.productNo;
    this.adultNumber = this.$route.query.adultNumber || 1;
    this.childNumber = this.$route.query.childNumber || 0;
    this.childBedNumber = this.$route.query.childBedNumber || 0;
    this.agedNumber = this.$route.query.agedNumber || 0;
    if (this.$route.query.tourTeamId) {
      this.tourTeamId = parseInt(this.$route.query.tourTeamId);
    }
    if (this.$route.query.orderNo) {
      this.orderNo = this.$route.query.orderNo;
      let tourContactsInfo = localStorage.getItem("tourContactsInfo");
      this.contactParams = JSON.parse(tourContactsInfo);
      localStorage.removeItem("tourContactsInfo");
    }
  },
  mounted() {
    this.getDetail();
  },
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {
    tourismCostNumArr(newVal) {
      let tourismCost = this.tourismCost;
      for (let i = 0, l = newVal.length; i < l; i++) {
        tourismCost[i].costNumber = newVal[i];
        tourismCost[i].costTotal = newVal[i] * tourismCost[i].castAmount;
      }
      let blankArr = [];
      this.tourismCost = blankArr.concat(tourismCost);
    },
    totalPrice(newVal) {
      console.log(newVal)
      this.resultPrice = newVal
      this.getResultAmount(newVal)
    },
  },
  computed: {

    adultDisabled() {
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (tourismTeamResult === null || tourismTeamResult === undefined || tourismTeamResult.length === 0) {
        return true;
      } else if (tourismTeamResult[index].balanceAdultPrice === 0 || tourismTeamResult[index].balanceAdultPrice === null) {
        return true;
      } else {
        return false;
      }
    },
    childBedDisabled() {
      // tourismTeamResult[productIndex].balanceChildBedPrice
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (
        tourismTeamResult === null ||
        tourismTeamResult === undefined ||
        tourismTeamResult.length === 0
      ) {
        return true;
      } else if (
        tourismTeamResult[index].balanceChildBedPrice === 0 ||
        tourismTeamResult[index].balanceChildBedPrice === null
      ) {
        return true;
      } else {
        return false;
      }
    },
    childDisabled() {
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (
        tourismTeamResult === null ||
        tourismTeamResult === undefined ||
        tourismTeamResult.length === 0
      ) {
        return true;
      } else if (
        tourismTeamResult[index].balanceChildPrice === 0 ||
        tourismTeamResult[index].balanceChildPrice === null
      ) {
        return true;
      } else {
        return false;
      }
    },
    agedDisabled() {
      let index = this.productIndex;
      let tourismTeamResult = this.tourismTeamResult;
      if (
        tourismTeamResult === null ||
        tourismTeamResult === undefined ||
        tourismTeamResult.length === 0
      ) {
        return true;
      } else if (
        tourismTeamResult[index].balanceAgedPrice === 0 ||
        tourismTeamResult[index].balanceAgedPrice === null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  filters: {
    returnFloatFilter(item) {
      let value = Math.round(parseFloat(item) * 100) / 100;
      let xsd = value.toString().split(".");
      if (xsd.length === 1) {
        value = value.toString() + ".00";
        return value;
      }
      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }
        return value;
      }
    },
  },
  methods: {
    getTotalPrice() {
      let index = this.productIndex;
      console.log(index, this.tourismTeamResult)
      if (this.tourismTeamResult.length === 0) {
        this.totalPrice = 0
        return
      }
      let totalNumber = this.adultNumber + this.childNumber + this.childBedNumber + this.agedNumber
      if (this.tourismTeamResult[index].inventoryType === 1 && totalNumber > this.tourismTeamResult[index].emptySeat) {
        return
      }
      let adultPrice = this.tourismTeamResult[index].balanceAdultPrice * this.adultNumber;
      let childPrice = this.tourismTeamResult[index].balanceChildPrice * this.childNumber;
      let childBedPrice = this.tourismTeamResult[index].balanceChildBedPrice * this.childBedNumber;
      let agedPrice = this.tourismTeamResult[index].balanceAgedPrice * this.agedNumber;
      let tourismCost = this.tourismCost;
      // let tourismCostNumArr = this.tourismCostNumArr
      let tourismCostPrice = 0;
      tourismCost.forEach((value) => {
        tourismCostPrice += value.costTotal;
      });
      this.totalPrice = adultPrice + childPrice + childBedPrice + agedPrice + tourismCostPrice
    },
    toHome() {
      this.$router.push({
        name: "distributor-tour-front-home",
      });
      document.documentElement.scrollTop = 0;
    },
    toSearch(query) {
      this.$router.push({
        name: "distributor-tour-front-search",
        query,
      });
      document.documentElement.scrollTop = 0;
    },
    toDetail() {
      this.$router.push({
        name: "distributor-tour-front-details",
        query: {
          productNo: this.productNo,
        },
      });
    },
    getDetail() {
      let params = {
        productNo: this.productNo,
      };
      web_common_productDetail(params).then((res) => {
        this.detailData = res;
        this.tourismNotice = res.tourismNotice;
        let tourismCost = res.tourismCost;
        let tourismCostNumArr = [];

        switch (this.detailData.productLevel) {
          case 1:
            this.detailData.productLevelText = "经典";
            break;
          case 2:
            this.detailData.productLevelText = "经济";
            break;
          case 3:
            this.detailData.productLevelText = "促销";
            break;
          case 4:
            this.detailData.productLevelText = "尊享";
            break;
          case 6:
            this.detailData.productLevelText = "臻品";
            break;
        }

        tourismCost.forEach((value) => {
          tourismCostNumArr.push(0);
          value.costNumber = 0;
          value.costTotal = 0;
        });
        this.tourismCostNumArr = tourismCostNumArr;
        this.tourismCost = tourismCost;
        let tourismTeamResult = res.tourismTeamResult;
        tourismTeamResult.forEach((value, index) => {
          value.dayValue = moment(value.teamTime, "YYYY-MM-DD hh:mm:ss").format(
            "YYYY-MM-DD"
          );
          if (this.tourTeamId === value.id) {
            this.productIndex = index;
            //this.tourTeamId = value.id
            // this.deportTime = value.dayValue
          }
          const day = moment(value.teamTime, "YYYY-MM-DD hh:mm:ss").format(
            "MM-DD"
          );
          const week = moment(value.teamTime, "YYYY-MM-DD hh:mm:ss").weekday();
          let weekFont = "";
          switch (week) {
            case 1:
              weekFont = "星期一";
              break;
            case 2:
              weekFont = "星期二";
              break;
            case 3:
              weekFont = "星期三";
              break;
            case 4:
              weekFont = "星期四";
              break;
            case 5:
              weekFont = "星期五";
              break;
            case 6:
              weekFont = "星期六";
              break;
            default:
              weekFont = "星期天";
          }
          value.label = `${day}${weekFont}出发,${value.balanceAdultPrice}元/成人价，${value.balanceChildBedPrice}元/儿童占床价，${value.balanceChildPrice}元/儿童不占床价，${value.balanceAgedPrice}元/老人价`;
        });
        this.tourismTeamResult = tourismTeamResult;
        // 如果没传入tourTeamId的话
        if (this.tourTeamId === "") {
          this.tourTeamId = tourismTeamResult[0].id;
          this.productIndex = 0;
        }

        this.getTotalPrice()
      });
    },
    changeTourTeamId(val) {
      for (let i = 0, l = this.tourismTeamResult.length; i < l; i++) {
        if (this.tourismTeamResult[i].id === val) {
          this.productIndex = i;
          break;
        }
      }
      this.getTotalPrice()
    },

    handleChangeAdult(val, oldVal) {
      let total = val + this.childBedNumber + this.childNumber + this.agedNumber;
      if (this.tourismTeamResult[this.productIndex].inventoryType === 1 && total > this.tourismTeamResult[this.productIndex].emptySeat) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.adultNumber = oldVal;
        });
      } else {
        this.getTotalPrice()
      }
    },
    handleChangeChildBed(val, oldVal) {
      let total = val + this.adultNumber + this.childNumber + this.agedNumber;
      if (this.tourismTeamResult[this.productIndex].inventoryType === 1 && total > this.tourismTeamResult[this.productIndex].emptySeat) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.childBedNumber = oldVal;
        });
      } else {
        this.getTotalPrice()
      }
    },
    handleChangeChild(val, oldVal) {
      let total =
        val + this.adultNumber + this.childBedNumber + this.agedNumber;
      if (this.tourismTeamResult[this.productIndex].inventoryType === 1 && total > this.tourismTeamResult[this.productIndex].emptySeat) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.childNumber = oldVal;
        });
      } else {
        this.getTotalPrice()
      }
    },
    handleChangeAged(val, oldVal) {
      let total =
        val + this.adultNumber + this.childBedNumber + this.childNumber;
      if (this.tourismTeamResult[this.productIndex].inventoryType === 1 && total > this.tourismTeamResult[this.productIndex].emptySeat) {
        this.$message({
          type: "warning",
          message: "人数不能超过余位",
        });
        this.$nextTick(() => {
          this.agedNumber = oldVal;
        });
      } else {
        this.getTotalPrice()
      }
    },
    getResultAmount(amount) {
      web_tourismFullReduction_orderReductionMsg({orderAmount: amount}).then(res => {
        let resultAmount = res.result
        resultAmount.ruleArr = []
        if (resultAmount.rule) {
          resultAmount.ruleArr = resultAmount.rule.split('。')
        }
        this.resultAmount = resultAmount
        if (this.resultAmount.isReduction === 1) {
          this.resultPrice = (this.resultPrice - this.resultAmount.reductionAmount) > -1 ? this.resultPrice - this.resultAmount.reductionAmount : 0
        }
      })
    },
    handleChangeNum(val) {},
    submit() {
      if (
        this.adultNumber === 0 &&
        this.childNumber === 0 &&
        this.childBedNumber === 0 &&
        this.agedNumber === 0
      ) {
        this.$message({
          type: "warning",
          message: "请输入人数！",
        });
        return;
      }
      this.$refs["form2"].validate((valid) => {
        if (valid) {
          let otherCost = [];
          this.tourismCost.forEach((value) => {
            let costParams = {
              costMoneyAmount: value.castAmount,
              costName: value.costName,
              costNumber: value.costNumber,
              costTotal: value.costTotal,
              id: value.id,
              orderNo: this.orderNo,
            };
            otherCost.push(costParams);
          });
          let params = {
            adultPopulation: this.adultNumber,
            childrenBedPopulation: this.childBedNumber,
            agedPopulation: this.agedNumber,
            childrenPopulation: this.childNumber,
            confirmWay: this.detailData.confirmWay,
            orderNo: this.orderNo,
            otherCost,
            productId: this.detailData.id,
            teamId: this.tourTeamId.toString(),
            teamTime: this.tourismTeamResult[this.productIndex].dayValue,
            travelAgency: Object.assign({}, this.contactParams),
          };
          web_specPlaceAnOrder(params).then((res) => {
            // 保存用户信息到本地缓存
            let tourContactsInfo = JSON.stringify(this.contactParams);
            localStorage.setItem("tourContactsInfo", tourContactsInfo);
            this.$router.push({
              name: "distributor-tour-front-write-traveller",
              query: {
                orderId: res.orderId,
                confirmWay: this.detailData.confirmWay,
                tourTeamId: this.tourTeamId,
                productId: this.detailData.id,
              },
            });
          });
        }
      });
    },
  },
};
