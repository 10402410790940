// web_tourismFullReduction_orderReductionMsg 根据用户ID和订单金额，返回满减信息--返回信息包含：是否满减、满减金额、满减活动标题、满减规则说明
const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 订单详情
 */
export default function web_tourismFullReduction_orderReductionMsg(val) {
    let pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/tourismFullReduction/orderReductionMsg"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}